import { AfterViewInit, EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { SortDirection } from '@swimlane/ngx-datatable';
import { DisplayResolution } from 'src/app/models/DisplayResolution';

import { TableHeader } from 'src/app/models/tables/TableHeader';
import { AlertService } from 'src/app/services/alert.service';
import StringUtils from 'src/app/utils/string-utils';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() description: string;
  @Input() tableHeaders: TableHeader[];
  @Input() data: any[];
  @Input() addRecordRouterLink: string;
  @Output() selectRowEvent = new EventEmitter<any>();
  private rows: any[] = [];
  private entriesPerPage: number = 10;
  private defaultSorts: any[] = [];
  private datatableFooterHeight: number = 50;
 
  constructor(private alertService: AlertService) { }

  ngOnInit() {
    this.rows = this.data;
    this.sortByDateByDefault();
  }

  ngAfterViewInit() {
    if (this.rows.length <= this.entriesPerPage) {
      this.datatableFooterHeight = 0;
    } else {
      this.datatableFooterHeight = 50;
    }
  }

  sortByDateByDefault() {
    const firstColumn = this.tableHeaders[0].property;
    if (firstColumn.toLowerCase().includes("date")) {
      this.defaultSorts = [{ prop: firstColumn, dir: SortDirection.desc }];
    }
  }

  formatCell(value: any) {
    if (value instanceof Date) {
      return StringUtils.formatDate(value);
    } else if (StringUtils.isCurrency(value.toString())) {
      return StringUtils.formatCurrency(value, true);
    } else if (Array.isArray(value) && value.length > 1 && typeof value[0] === "string") {
      return value.join(", ");
    }
    return value;
  }

  onChangeEntriesPerPage($event) {
    this.entriesPerPage = $event.target.value;
  }

  onSearch($event) {
    const searchTerm = $event.target.value.toLowerCase();
    const currentResolution = DisplayResolution.getCurrentResolution();
    const visibleColumns = this.getVisibleColumns(currentResolution);
    this.rows = this.data.filter((row) => {
      for (let column of visibleColumns) {
        const cell = row[column.property];
        if (cell) {
          const value = String(this.formatCell(cell)).toLowerCase();
          if (value.includes(searchTerm)) {
            return true;
          }
        }
      }
      return false;
    });
  }
  
  getVisibleColumns(resolution: DisplayResolution): TableHeader[] {
    const resolutionIndex = Object.values(DisplayResolution).indexOf(resolution);
    return this.tableHeaders.filter((_, index) => index <= resolutionIndex);
  }

  onClickRow(event) {
    if (event.type == 'click') {
      this.selectRowEvent.emit(event.row);
    }
  }

  rowClass = () => {
    return {
      'table-row': true
    };
  }

}
