import { Component, OnInit } from "@angular/core";
import { User } from "src/app/models/User";
import { UserRole } from "src/app/models/UserRole";
import { AuthService } from "src/app/services/auth.service";

import { appVersion } from 'src/environments/version';

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  private now: Date = new Date();
  private loggedInUser: User;
  private appVersion: string = appVersion;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.loggedInUser = this.authService.getLoggedInUser();
  }

  isLoggedInUserAdmin(): boolean {
    return this.loggedInUser && this.loggedInUser.role === UserRole.ADMIN;
  }
}
