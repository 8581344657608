import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

import { SharedUser } from 'src/app/models/SharedUser';
import { User } from 'src/app/models/User';
import { UserVehicle } from 'src/app/models/UserVehicle';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserVehicleService } from 'src/app/services/user-vehicle.service';
import { RouteHistory } from 'src/app/services/route-history.service';
import { AlertService } from 'src/app/services/alert.service';
import { UserService } from 'src/app/services/user.service';
import { UserPreference } from 'src/app/models/UserPreference';
import { VehicleSettings } from 'src/app/models/VehicleSettings';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-garage-splash',
  templateUrl: './garage-splash.component.html',
  styleUrls: ['./garage-splash.component.scss']
})
export class GarageSplashComponent implements OnInit {
  private updateMileageModal: BsModalRef;
  private shareVehicleModal: BsModalRef;
  private userVehicles: UserVehicle[] = [];
  private vehicleSettings: VehicleSettings = new VehicleSettings();
  private loggedInUser: User;
  private loggedInUserIsOwner: boolean = false;
  private manageSubscriptionUrl: string;

  constructor(
    private router: Router,
    private userService: UserService,
    private userVehicleService: UserVehicleService,
    private authService: AuthService,
    private alertService: AlertService,
    private modalService: BsModalService,
    private toastService: ToastService,
    private subscriptionService: SubscriptionService,
    private routeHistory: RouteHistory
  ) { }

  ngOnInit(): void {
    this.loggedInUser = this.authService.getLoggedInUser();
    this.userVehicleService.setCurrentVehicle(null);
    this.userVehicleService.getUserVehicles().subscribe(userVehicles => {
      this.userVehicles = userVehicles;
      if (!this.routeHistory.getPreviousUrl() || this.routeHistory.getPreviousUrl() === "/login") {
        this.openFavoriteVehicleIfExistsAndPreferenceEnabled();
      }
    });
  }

  openFavoriteVehicleIfExistsAndPreferenceEnabled() {
    const favoriteVehicle = this.userVehicleService.getFavoriteVehicle(this.userVehicles);
    if (favoriteVehicle && this.loggedInUser.preferences.openFavoriteVehicleOnLogin) {
      this.setCurrentVehicleAndNavigateToTheDashboard(favoriteVehicle);
    }
  }

  onClickVehicle(userVehicle: UserVehicle) {
    this.setCurrentVehicleAndNavigateToTheDashboard(userVehicle);
  }

  onClickLoadDemoVehicle() {
    this.userVehicleService.generateDemoVehicle().subscribe((savedVehicle) =>
      this.setCurrentVehicleAndNavigateToTheDashboard(savedVehicle));
  }

  setCurrentVehicleAndNavigateToTheDashboard(userVehicle: UserVehicle) {
    this.userVehicleService.getUserVehicles().subscribe(userVehicles => { // TODO is this call needed?
      this.userVehicleService.setCurrentVehicle(userVehicle);
      this.router.navigate(['/dashboard']);
    });
  }

  onClickFavoriteIcon(userVehicle: UserVehicle, event: any) {
    this.preventSelectionOfVehicleWhenClickingOnIconOrLink(event);
    if (!userVehicle.favorite) {
      this.unsetCurrentFavorite();
    }

    userVehicle.favorite = !userVehicle.favorite;

    let updateIndicator = false;
    if (!this.userPreviouslyFavoritedAVehicle()) {
      updateIndicator = true;
      this.loggedInUser.indicators.previouslyFavoritedAVehicle = true;
      this.authService.setLoggedInUser(this.loggedInUser);
      this.showPopupForSettingFavoriteVehiclePreference();
    }
    this.userVehicleService.setFavorite(userVehicle.id, updateIndicator).subscribe();
  }

  unsetCurrentFavorite() {
    const favoriteVehicle = this.userVehicles.find(vehicle => vehicle.favorite);
    if (favoriteVehicle) {
      favoriteVehicle.favorite = false;
    }
  }

  userPreviouslyFavoritedAVehicle() {
    return this.loggedInUser.indicators.previouslyFavoritedAVehicle;
  }

  showPopupForSettingFavoriteVehiclePreference() {
    this.alertService.showQuestion("Do you want open your favorite vehicle when you log in?",
        null, "Yes!", "No, take me to the garage when I log in").then((result) => {
        if (result.value) { // Clicked "Yes"
          this.userService.updatePreference(UserPreference.OPEN_FAVORITE_VEHICLE_ON_LOGIN, true).subscribe();
          this.loggedInUser.preferences.openFavoriteVehicleOnLogin = true;
          this.authService.setLoggedInUser(this.loggedInUser);
        }
        this.alertService.showConfirmation(null, "Great, you can update this anytime in the 'Preferences' settings");
    });
  }

  onClickShareIcon(userVehicle: UserVehicle, modal: TemplateRef<any>, event: any) {
    this.preventSelectionOfVehicleWhenClickingOnIconOrLink(event);
    this.getVehicleSettingsAndShowShareVehicleModal(userVehicle.id, modal);
  }

  getVehicleSettingsAndShowShareVehicleModal(userVehicleId: string,  modal: TemplateRef<any>) {
    this.userVehicleService.getSettings(userVehicleId).subscribe(settings => {
      this.vehicleSettings = settings;
      this.determineIfLoggedInUserIsOwner();
      this.shareVehicleModal = this.modalService.show(modal, {
        keyboard: true,
        class: "modal-dialog-centered"
      });
    });
  }

  onClickCloseShareVehicleModal() {
    this.shareVehicleModal.hide();
  }

  onDoneSharingVehicle(sharedUsers: SharedUser[]) {
    if (this.loggedInUserIsOwner) {
      this.userVehicleService.share(this.vehicleSettings.id, sharedUsers).subscribe(() => {
        this.shareVehicleModal.hide();
        this.vehicleSettings = new VehicleSettings();
      });
    } else {
      this.shareVehicleModal.hide();
    }
  }

  onClickUpdateMileage(modal: TemplateRef<any>, event: any) {
    this.preventSelectionOfVehicleWhenClickingOnIconOrLink(event);
    this.updateMileageModal = this.modalService.show(modal, {
      keyboard: true,
      backdrop: 'static',
      class: "modal-dialog-centered"
    });
  }

  onDoneUpdatingMileage(userVehicle: UserVehicle, mileage: number) {
    this.userVehicleService.updateMileage(mileage, userVehicle.id).subscribe(() => {
      this.updateMileageModal.hide();
      userVehicle.mileage = mileage;
      this.toastService.showSuccess("Updated successfully");
    });
  }

  onClickCloseUpdateMileageModal() {
    this.updateMileageModal.hide();
  }

  determineIfLoggedInUserIsOwner() {
    const owner: SharedUser = this.vehicleSettings.sharedUsers.find(user => user.owner);
    if (this.vehicleSettings && (owner.id === this.loggedInUser.id)) {
      this.loggedInUserIsOwner = true;
    } else {
      this.loggedInUserIsOwner = false;
    }
  }

  onClickUpgradeSubscription() {
    this.subscriptionService.manageSubscription().subscribe(manageSubscription => {
      window.location.href = manageSubscription.sessionUrl;
    });
  }

  isOneOrMoreVehiclesDisabled() {
    for (let userVehicle of this.userVehicles) {
      if (!userVehicle.enabled) {
        return true;
      }
    }
    return false;
  }

  preventSelectionOfVehicleWhenClickingOnIconOrLink(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

}
