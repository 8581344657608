import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { UserVehicleSharedService } from 'src/app/services/shared/user-vehicle.shared.service';
import { UserVehicle } from 'src/app/models/UserVehicle';
import { VehicleDetails } from 'src/app/models/VehicleDetails';
import { SharedUser } from 'src/app/models/SharedUser';
import { Statistics } from 'src/app/models/Statistics';
import ApiRequestHelper from 'src/app/utils/api-request-helper';
import { VehicleSettings } from '../models/VehicleSettings';
import { VehicleSettingsService as Service} from '../models/VehicleSettingsService';
import { VehicleSettingsReminder as Reminder } from '../models/VehicleSettingsReminder';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class UserVehicleService {
  private getUrl = environment.apiUrl + '/user/vehicles/get';
  private addUrl = environment.apiUrl + '/user/vehicles/add';
  private updateMileageUrl = environment.apiUrl + '/user/vehicles/updateMileage';
  private getStatisticsUrl = environment.apiUrl + '/user/vehicles/getStatistics';
  private getDetailsUrl = environment.apiUrl + '/user/vehicles/getDetails';
  private updateDetailsUrl = environment.apiUrl + '/user/vehicles/updateDetails';
  private setFavoriteUrl = environment.apiUrl + '/user/vehicles/setFavorite';
  private deleteUrl = environment.apiUrl + '/user/vehicles/delete';
  private shareUrl = environment.apiUrl + '/user/vehicles/share';
  private acceptInviteUrl = environment.apiUrl + '/user/vehicles/acceptInvite';
  private getSettingsUrl = environment.apiUrl + '/user/vehicles/getSettings';
  private updateSettingsUrl = environment.apiUrl + '/user/vehicles/updateSettings';
  private updateServicesUrl = environment.apiUrl + '/user/vehicles/updateServices';
  private updateRemindersUrl = environment.apiUrl + '/user/vehicles/updateReminders';
  private generateDemoVehicleUrl = environment.apiUrl + '/user/vehicles/generateDemo';

  constructor(
    private http: HttpClient,
    private userVehicleSharedService: UserVehicleSharedService
  ) { }

  getCurrentVehicle(): UserVehicle {
    return JSON.parse(localStorage.getItem('currentVehicle')) as UserVehicle;
  }

  setCurrentVehicle(userVehicle: UserVehicle): void {
    this.userVehicleSharedService.setCurrentVehicle(userVehicle);
    localStorage.setItem("currentVehicle", JSON.stringify(userVehicle));
  }

  setCurrentVehicleForUserVehicles(userVehicles: UserVehicle[]): void {
    const favoriteVehicle = this.getFavoriteVehicle(userVehicles);
    if (favoriteVehicle) {
        this.setCurrentVehicle(favoriteVehicle);
    } else {
        this.setCurrentVehicle(userVehicles[0]);
    }
  }

  getFavoriteVehicle(userVehicles: UserVehicle[]): UserVehicle {
    return userVehicles.find(userVehicle => userVehicle.favorite);
  }

  getUserVehiclesFromLocalStorage(): UserVehicle[] {
    return JSON.parse(localStorage.getItem('userVehicles')) as UserVehicle[];
  }

  getUserVehicles(): Observable<UserVehicle[]> {
    return this.http.post<UserVehicle[]>(this.getUrl, null, httpOptions).pipe(
      map(userVehicles => {
        this.setUserVehicles(userVehicles);
        return userVehicles;
      })
    );
  }

  setUserVehicles(userVehicles: UserVehicle[]) {
    this.userVehicleSharedService.setUserVehicles(userVehicles);
    localStorage.setItem("userVehicles", JSON.stringify(userVehicles));
  }

  addVehicle(userVehicle: UserVehicle): Observable<UserVehicle> {
    ApiRequestHelper.cleanJsonPayloadObject(userVehicle);
    return this.http.post<UserVehicle>(this.addUrl, userVehicle, httpOptions);
  }

  updateMileage(mileage: number, userVehicleId?: string): Observable<any> {
    const body = {
      userVehicleId: userVehicleId ? userVehicleId : this.getCurrentVehicle().id,
      mileage
    }
    return this.http.post<any>(this.updateMileageUrl, body, httpOptions);
  }

  getStatistics(): Observable<Statistics> {
    const body = {
      userVehicleId: this.getCurrentVehicle().id
    }
    return this.http.post<any>(this.getStatisticsUrl, body, httpOptions).pipe(
      map(statistics => {
        if (statistics.mileage.lastUpdatedDate) {
          statistics.mileage.lastUpdatedDate = new Date(statistics.mileage.lastUpdatedDate);
        }
        if (statistics.mostRecentService) {
          statistics.mostRecentService.date = new Date(statistics.mostRecentService.date);
        }
        return statistics;
      })
    );
  }

  getDetails(): Observable<VehicleDetails> {
    const body = {
      userVehicleId: this.getCurrentVehicle().id
    }
    return this.http.post<any>(this.getDetailsUrl, body, httpOptions);
      // .pipe(
      //   map(response => {
      //     response.purchaseInformation.purchasedDate = new Date(response.purchaseInformation.purchasedDate);
      //     return response;
      //   })
      // );
  }

  updateDetails(details: VehicleDetails): Observable<any> {
    const body = {
      userVehicleId: this.getCurrentVehicle().id,
      details: details
    }
    return this.http.post<any>(this.updateDetailsUrl, body, httpOptions);
  }

  setFavorite(userVehicleId: string, updateIndicator: boolean = false) {
    const body = {
      userVehicleId,
      updateIndicator
    }
    return this.http.post<any>(this.setFavoriteUrl, body, httpOptions);
  }

  delete(userVehicleId: string) {
    const body = {
      userVehicleId
    }
    return this.http.post<any>(this.deleteUrl, body, httpOptions);
  }

  share(userVehicleId: string, sharedUsers: SharedUser[]) {
    const body = {
      userVehicleId,
      sharedUsers
    }
    return this.http.post<any>(this.shareUrl, body, httpOptions);
  }

  acceptInvite(token: string) {
    const body = {
      token
    }
    return this.http.post<any>(this.acceptInviteUrl, body, httpOptions);
  }

  getSettings(userVehicleId: string): Observable<VehicleSettings> {
    const body = {
      userVehicleId
    }
    return this.http.post<any>(this.getSettingsUrl, body, httpOptions);
  }

  updateSettings(userVehicleId: string, settings: VehicleSettings): Observable<any> {
    const body = {
      userVehicleId,
      data: {
        nickname: settings.nickname,
        milesDrivenPerYear: settings.milesDrivenPerYear
      }
    }
    return this.http.post<any>(this.updateSettingsUrl, body, httpOptions);
  }

  updateServices(userVehicleId: string, services: Service[]) {
    const body = {
      userVehicleId,
      services
    }
    return this.http.post<any>(this.updateServicesUrl, body, httpOptions);
  }

  updateReminders(userVehicleId: string, reminders: Reminder[]) {
    const body = {
      userVehicleId,
      reminders
    }
    return this.http.post<any>(this.updateRemindersUrl, body, httpOptions);
  }

  generateDemoVehicle() {
    return this.http.post<any>(this.generateDemoVehicleUrl, null, httpOptions);
  }
}
