import { SharedUser } from 'src/app/models//SharedUser';
import { VehicleSettingsService } from 'src/app/models/VehicleSettingsService';
import { VehicleSettingsReminder } from './VehicleSettingsReminder';

export class VehicleSettings {
    id: string;
    nickname: string;
    milesDrivenPerYear: number
    sharedUsers: SharedUser[];
    services: VehicleSettingsService[];
    reminders: VehicleSettingsReminder[];
}